import React from "react";
import Header from "./Headers/Header";

function Objekti() {
    return (
        <div className="mx-auto font-sans text-white">
            <Header/>
            <div className="h-20 mt-40">
                <h1 className="text-6xl text-blue-900">COMING SOON...</h1>
            </div>
        </div>
    );
}

export default Objekti;